import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../layouts/layout"
import SocialLinks from "../components/social-links"
import { EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/outline'
import ContactForm from "../components/forms/contact"

export default function ContactUs({ data }) {
  const title  = data.components.title
  const components = data.components.relationships.field_form_text_components
  const text = components[0]
  const contactDetails = components[1]
  return (
  <Layout hideCta="true">
    <div className="relative bg-white">
      <div className="relative mx-auto max-w-7xl lg:grid lg:grid-cols-5">
        <div className="bg-gray-50 pb-16 pt-32 px-4 sm:px-6 lg:col-span-2 lg:px-8 md:pt-40 lg:pt-48 lg:pb-24">
          <div className="mx-auto max-w-lg text-lg leading-6 text-gray-500">
            <h1 className="mt-2 mb-6 text-2xl font-bold tracking-tight text-sky-900 sm:text-3xl">{title}</h1>
            <div className="textarea" dangerouslySetInnerHTML={{ __html: text.field_news_text_body.processed }} />
            <dl className="mt-8 text-base text-gray-900">
              <div className="mt-6">
                <dt className="sr-only">Phone number</dt>
                <dd className="flex">
                  <PhoneIcon className="h-6 w-6 flex-shrink-0 text-sky-700" aria-hidden="true" />
                  <span className="ml-3">{contactDetails.field_contact_phone}</span>
                </dd>
              </div>
              {contactDetails.field_contact_email && <div className="mt-3">
                <dt className="sr-only">Email</dt>
                <dd className="flex">
                  <EnvelopeIcon className="h-6 w-6 flex-shrink-0 text-sky-700" aria-hidden="true" />
                  <span className="ml-3">{contactDetails.field_contact_email}</span>
                </dd>
              </div>}
              <div className="mt-8 flex space-x-3">
                <SocialLinks />
              </div>
            </dl>
          </div>
        </div>
        <div className="bg-white py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 lg:pt-56 lg:pb-24">
          <div className="mx-auto max-w-lg lg:max-w-none">
              <ContactForm />
          </div>
        </div>
      </div>
    </div>
  </Layout>
  )
}

export const Head = ({ location, data }) => {
  const canonicalUrl = `${process.env.GATSBY_SITE_URL}contact-us/`
  const metaDescription = data.components?.field_metatags?.description ?? ``
  return (
    <>
      <meta name="description" content={metaDescription} />
      <title>Contact us | DEC Housing</title>
      <link rel="canonical" href={canonicalUrl} />
    </>
  )
}

export const query = graphql`
  query ContactUsPageQuery {
    components: nodeForm(drupal_internal__nid: {eq: 12}) {
      field_metatags {
        description
      }
      title
      relationships {
        field_form_text_components {
          ... on paragraph__contact_info {
            field_contact_email
            field_contact_phone
          }
          ... on paragraph__text {
            field_news_text_body {
              processed
            }
          }
        }
      }
    }
  }
`



